import React from 'react';
import './FormGroup.scss';
import {ErrorMessage} from "formik";

export const FormGroup = (props) => {
    const { label, children, id, name } = props;
    return (
        <div className="form-group">
            <label htmlFor={id || name}>
                {label}
            </label>
            {children}
            <ErrorMessage
                name={id || name}
                component="span"
                className="error"
            />
        </div>
)
    ;
};
