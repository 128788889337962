import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { FormGroup } from '../FormGroup';

import './TextArea.scss';
import {value} from "lodash/seq";


export const TextArea = ({ label, mask, disabled, ...props}) => {
    const { id, name, type, placeholder } = props;
    const {...fieldProps } = props;
    const [{value}, { error, touched }] = useField(fieldProps);
    const inputClasses = cx(
        'input',
        { 'input--error': touched && error != null},
        { 'input--success': touched && error == null},
    );

    return (
        <FormGroup {...{ label, id, name }}>
            <textarea className={inputClasses}>
                {value}
            </textarea>
        </FormGroup>
    );
};