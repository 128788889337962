import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ButtonList.scss';


// eslint-disable-next-line react/prop-types
export const ButtonList = ({ onClick, submit, inverted, disabled, children, loading, success }) => {
    const className = cx(
        'crm__button-list',
        { 'crm__button-list--disabled': disabled },
    );
    const type = submit ? 'submit' : 'button';
    return (
        <button {...{ onClick, className, type, disabled }}>
            {children}
        </button>
    );
};

ButtonList.propTypes = {
    children: PropTypes.node,
    submit: PropTypes.bool,
    inverted: PropTypes.bool,
    disabled: PropTypes.bool,
};
