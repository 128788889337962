import React, {useEffect, useMemo} from 'react';
import './ListPagination.scss';
import {Select} from "react-select-virtualized";
import {useField, useFormikContext} from "formik";
import {ButtonList} from "../ButtonList/ButtonList";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {Input} from "../field-components/Input";


export const PaginationChange = ({pagination}) => {

    const [{value}, {test}, {setValue}] = useField('page');

    const { submitForm, isSubmitting } = useFormikContext();
    const onChangePlus = () => {
        if(pagination.current_page !== pagination.total_pages){
            let newValue = value;
            setValue(newValue + 1);
            submitForm();
        }
    }
    const onChangeMinus = () => {
        if(pagination.current_page > 1){
            let newValue = value;
            setValue(newValue - 1);
            submitForm();
        }
    }

    return (
        <>
            <button type="button" disabled={pagination.current_page <= 1 || isSubmitting} onClick={onChangeMinus}>
                <IoIosArrowBack/>
            </button>
            <button type="button" disabled={pagination.current_page === pagination.total_pages || isSubmitting} onClick={onChangePlus}>
                <IoIosArrowForward />
            </button>
        </>
    );
};
