import React, {useEffect, useMemo} from 'react';
import './ListPagination.scss';
import {Select} from "react-select-virtualized";
import {useField, useFormikContext} from "formik";
import {ButtonList} from "../ButtonList/ButtonList";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {PaginationChange} from "./PaginationChange";


export const ListPagination = ({pagination}) => {

    const [{value}, {test}, {setValue}] = useField('per_page');
    const [page, errorPage, setPage] = useField('page');
    const { submitForm } = useFormikContext();

    const options = [
        {'label': '5', 'value': 5},
        {'label': '10', 'value': 10},
        {'label': '20', 'value': 20},
        {'label': '50', 'value': 50},
        {'label': '100', 'value': 100},
    ]
    const onChange = (newValue) => {
        setValue(newValue.value);
        setPage.setValue(1)
        submitForm();
    }

    const innerValue = useMemo(() => options.find((it) => it.value === value), [options, value]);

    return (
        <div className="crm__list-pagination">
            <div className="crm__list-pagination-container">
                <div className="crm__list-pagination-inserted">
                    <div>Елементів на сторінці:</div>
                    <Select
                        optionHeight={42}
                        options={options}
                        value={innerValue}
                        onChange={onChange}
                        isClearable={false}
                        styles={{
                            menu: () => ({
                                bottom: "100%",
                                position: "absolute",
                                zIndex: 1,
                                width: "100%",
                                margin: "5px 0",
                                backgroundColor: "white",
                                boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1)"
                            }),
                        }}
                    />
                </div>
                <div className="crm__list-pagination-pages">
                    <div className="crm__list-pagination-text">
                        {pagination.current_page} / {pagination.total_pages}
                    </div>
                    <div className="crm__list-pagination-buttons">
                        <PaginationChange pagination={pagination}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
