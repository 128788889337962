import React from 'react';
import './ListFilterModal.scss';
import {TextRequiredField} from "../field-components/TextRequiredField/TextRequiredField";
import Modal from "react-modal";
import {RiFilterLine} from "react-icons/ri";
import {Button} from "../Button/Button";
import {useFormikContext} from "formik";
import {VendorCodeField} from "../field-components/VendorCodeField/VendorCodeField";
import {PriceField} from "../field-components/PriceField/PriceField";

export const ListFilterModal = ({modalIsOpen, setIsOpen}) => {
    const { isSubmitting, isValid, dirty } = useFormikContext();

    const customStyles = {
        overlay: {
            background: 'rgb(0 0 0 / 35%)',
        },
        content: {
            top: '0',
            right: '0px',
            height: '100vh',
            width: '40vw',
            left: '60vw',
        },
    };

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            closeTimeoutMS={200}
            ariaHideApp={false}
        >
            <div className="crm__product-create-modal">
                <div className="crm__product-create-modal-header">
                    <RiFilterLine size="24px"/>
                    <h3>Фільтри</h3>
                </div>
                <section className="lcz-form__section">
                    <div className="tf-form__row">
                        <div className="tf-form__col w50">
                            <TextRequiredField name="status" label="status" type="text"/>
                        </div>
                        <div className="tf-form__col w50">
                            <PriceField name="price"/>
                        </div>
                    </div>
                </section>
                <section className="lcz-form__section lcz-form__submit-section">
                    <div className="lcz-form__checking">
                    </div>
                    <p className="lcz-form__submit-row">
                        <Button submit disabled={isSubmitting || !isValid || !dirty}>Продовжити</Button>
                    </p>
                </section>
            </div>
        </Modal>
    );
};
