import axios from "axios";
const endpoint = 'https://api.trueflex.com.ua/api/client';
export default class Client {
    static async get(data) {
        return await axios.get(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        });
    }
}