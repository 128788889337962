import React, {useEffect, useMemo} from 'react'
import {Link, useNavigate} from "react-router-dom"
import Layout from "../../components/Layout"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik";
import "./OrderCreate.scss";
import * as Yup from 'yup';
import {Input} from "../../components/field-components-new/Input";
import NbuApi from "../../api/NbuApi";
import Product from "../../api/Product";
import Order from "../../api/Order";
import {SearchField} from "../../components/field-components/SearchField";
import Select from "react-select/base";
import {SearchDropdownField} from "../../components/field-components-new/SearchDropdownField";
import {TextArea} from "../../components/field-components-new/TextArea";
import {ButtonAdd} from "../../components/ButtonAdd/ButtonAdd";
import {GoPlus} from "react-icons/go";
import {HeaderNav} from "../../components/HeaderNav";
import ProductCreate from "../Product/ProductCreate";
import {ProductCart} from "../../components/ProductCart/ProductCart";



function OrderCreate() {
    const navigate = useNavigate();

    const initialValues = {
        phone: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        edrpou: "",
        name_company: "",
        iban_company: "",
        address_company: "",
        type_company: "ФОП",
        comment: 'test',
        products: [{'id': 2, 'price': 20, 'count': 1}],
    };

    const SignInSchema = Yup.object().shape({
        phone: Yup.string().required('це обов\'язкове поле').min(12),
        first_name: Yup.string().required().min(3),
        last_name: Yup.string().required().min(3),
        middle_name: Yup.string(),
        type_company: Yup.string(),
        name_company: Yup.string().required().min(3).max(128),
       // address_company: Yup.string().required().min(3).max(128),
        iban_company: Yup.string().required().min(29).max(29),
        comment: Yup.string(),
        products: Yup.array(),
    });

    const onChange = (value) => {
        console.log(value);
    };


    const BankInfo = async (mfo, setFieldValue) =>{
        await NbuApi.getBankInfo(mfo).then(function (response) {
            if(response.data.length > 0){
                let bank_name = response.data[0]?.SHORTNAME;
                setFieldValue('iban_mfo', mfo);
                setFieldValue('iban_bank', bank_name);
            } else {
                setFieldValue('iban_mfo', '');
                setFieldValue('iban_bank', '');
            }
        });
    }

    const onSubmit = async (values) => {
        const result = await Order.create(values);
        if (result.success) {
            console.log(result)
            navigate("/orders");
        }
    };


    const BankFields = (props) => {
        const {
            values: { iban_company },
            setFieldValue,
            errors,
        } = useFormikContext();
       // const [field, meta] = useField(props);

        useEffect( () => {
            if (iban_company && !errors?.iban_company?.lenght && iban_company != "UA") {
                let mfo = iban_company.substring(4, 10);
                BankInfo(mfo, setFieldValue);
            }
        }, [iban_company])
        return (
            <div className="tf-form__row">
                <div className="tf-form__col w33">
                    <Input type="text" name="iban_company" label="Рахунок (IBAN)"
                           mask="UA999999999999999999999999999"></Input>
                </div>
                <div className="tf-form__col w33">
                    <Input type="text" name="iban_bank" label="Банк" disabled></Input>
                </div>
                <div className="tf-form__col w33">
                    <Input type="text" name="iban_mfo" label="МФО" disabled></Input>
                </div>
            </div>
        )
    }

    const ClientFields = (props) => {
        const {
            values: { search },
            setFieldValue,
            errors,
        } = useFormikContext();
        // const [field, meta] = useField(props);

        useEffect( () => {
            if (search) {
                setFieldValue('phone', search.phone);
                setFieldValue('last_name', search.last_name);
                setFieldValue('first_name', search.first_name);
                setFieldValue('middle_name', search.middle_name);
            }
        }, [search])

        return (
            <div className="tf-form__row">
                <div className="tf-form__col w33">
                    <Input type="text" name="phone" label="Номер телефону"
                           mask="+38 (099) 999 99 99"></Input>
                </div>
                <div className="tf-form__col w33">
                    <Input type="text" name="first_name" label="Прізвище"></Input>
                </div>
                <div className="tf-form__col w33">
                    <Input type="text" name="last_name" label="Імя"></Input>
                </div>
                <div className="tf-form__col w33">
                    <Input type="text" name="middle_name"
                           label="По батькові"></Input>
                </div>
            </div>
        )
    }

    return (
        <div>
            <HeaderNav title="Створення замовлення"></HeaderNav>
            <ProductCart></ProductCart>
            <div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/orders">Повернутись назад
                        </Link>
                    </div>
                    <div className="card-body">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={SignInSchema}
                            onSubmit={onSubmit}
                        >
                            {(formik) => {
                                const {
                                    errors,
                                    touched,
                                    isSubmitting, isValid,
                                    dirty, values,
                                    setFieldValue,
                                    value,
                                } = formik;

                                return (
                                    <div className="container">
                                        <Form>

                                            <FieldArray
                                                name="products"
                                                render={arrayHelpers => (
                                                    <div>
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.insert(0, '')} // insert an empty string at a position
                                                        >
                                                            +
                                                        </button>
                                                        {values.products && values.products.length > 0 ? (
                                                            values.products.map((product, index) => (
                                                                <div key={index}>
                                                                    <Field name={`products.${index}.id`}/>
                                                                    <Field name={`products.${index}.price`}/>
                                                                    <Field name={`products.${index}.count`}/>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                    >
                                                                        -
                                                                    </button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                {/* show this when user has removed all friends from the list */}
                                                                Add a friend
                                                            </button>
                                                        )}

                                                        <div>
                                                            <button type="submit">Submit</button>
                                                        </div>
                                                    </div>
                                                )}
                                            />

                                            <div className="tf-form__row">
                                                <SearchDropdownField name="search" placeholder="Назва або код товару"/>
                                            </div>
                                            <ClientFields></ClientFields>

                                            <div className="tf-form__row">
                                                <div className="tf-form__col w33">
                                                    <Input type="text" name="edrpou" label="ЄДРПОУ"
                                                           mask="99999999"></Input>
                                                </div>
                                                <div className="tf-form__col w33">
                                                    <Input type="text" name="type_company"
                                                           label="Тип"></Input>
                                                </div>
                                                <div className="tf-form__col w33">
                                                    <Input type="text" name="address_company"
                                                           label="Адреса"></Input>
                                                </div>
                                                <div className="tf-form__col w33">
                                                    <Input type="text" name="name_company"
                                                           label="Назва компанії"></Input>
                                                </div>
                                            </div>

                                            <BankFields/>

                                            <div className="tf-form__col w33">
                                                <TextArea name="comment"></TextArea>
                                            </div>
                                            <button
                                                type="submit"
                                                className={isSubmitting ? "disabled-btn" : ""}
                                                disabled={isSubmitting}
                                            >
                                                Продовжить
                                            </button>
                                        </Form>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default OrderCreate;